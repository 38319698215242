<ion-content scrollY="false">
  <div class="one3d-unsupported-wrapper">
    <div class="one3d-unsupported-container">
      <div class="one3d-orientation-icon">
        <img src="assets/img/icons/one3d-unsupported-icon.svg" alt="unsupported browser icon" />
      </div>
      <div class="one3d-orientation-mid">
        <h1 translate>BROWSER_SUPPORT.UNSUPPORTED_BROWSER</h1>
        <p>{{'BROWSER_SUPPORT.NO_SUPPORT' | translate}} <span translate>BROWSER_SUPPORT.THE_JEEP_EXPERIENCE</span></p>
      </div>
      <div class="one3d-unsupported-footer">
        <p class="one3d-unsupported-footer-para" translate>BROWSER_SUPPORT.BEST_EXPERIENCE</p>
        <div class="one3d-unsupported-social-wrapper">
          <div class="one3d-unsupported-social">
            <img class="one3d-unsupported-social-icon" src="assets/img/icons/one3d-google-icon.svg"
              alt="google chrome icon">
            <span class="one3d-unsupported-social-name">Google Chrome</span>
          </div>
          <div class="one3d-unsupported-social">
            <img class="one3d-unsupported-social-icon" src="assets/img/icons/one3d-safari-icon.svg" alt="safari icon">
            <span class="one3d-unsupported-social-name">Safari</span>
          </div>
          <div class="one3d-unsupported-social">
            <img class="one3d-unsupported-social-icon" src="assets/img/icons/one3d-ms-edge-icon.svg"
              alt="microsoft edge icon">
            <span class="one3d-unsupported-social-name">Microsoft Edge</span>
          </div>
          <div class="one3d-unsupported-social">
            <img class="one3d-unsupported-social-icon" src="assets/img/icons/one3d-firefox-icon.svg" alt="firefox icon">
            <span class="one3d-unsupported-social-name">Mozilla Firefox</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>